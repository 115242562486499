.descansoUno {
  // margin-top: 180px;
  padding: 280px 0px 180px;
  color: #333;
  background-color: rgb(235, 235, 235);
  .fraseContainer {
    margin-top: -140px;
    text-align: left;
    font-size: 3rem;
    display: grid;
    grid-template-columns: 10% 40% 40% 10%;

    width: 100%;
    .imagesDescanso {
      display: grid;
      height: 50%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-column: 2;
      .imgDescanso {
        width: 60%;
        grid-column: 1;
        grid-row: 1;
        justify-self: center;
        margin: 23% 0px;
        box-shadow: 0 0 50px 0 #00000075;
      }
      :first-child {
        width: 36%;
        align-self: start;
        justify-self: end;
        margin-top: 9%;
        margin-right: 16px;
        box-shadow: 0 0 50px 0 #00000075;
      }
      :last-child {
        width: 30%;
        margin-top: 58%;
        align-self: end;
        justify-self: start;
        box-shadow: 0 0 50px 0 #00000075;
      }
    }
    .fraseDescanso1 {
      grid-column: 3;
      font-size: 2rem;
      margin-top: 30%;
      margin-top: 50%;
      width: 79%;
    }
  }
  div {
    background: linear-gradient(
      145deg,
      #ffdbf1,
      #daf4f2 32%,
      #7dccf0 74%,
      #2013bb
    );
    background: linear-gradient(145deg, #888 32%, #06a8f2 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and (max-width: 1200px) {
  .descansoUno {
    // padding: 0;
    .fraseContainer {
      grid-template-columns: 10% 80% 10%;
      grid-template-columns: 10% 35% 50% 10%;

      .imagesDescanso {
        .imgDescanso {
        }
        :first-child {
        }
        :last-child {
        }
      }
      .fraseDescanso1 {
      }
    }
  }
}

@media screen and (max-width: 790px) {
  .descansoUno {
    // padding: 0;
    .fraseContainer {
      font-size: 2rem;
      grid-template-columns: 10% 80% 10%;
      grid-template-rows: 1fr 0.6fr;
      .imagesDescanso {
        display: grid;
        margin-top: 60px;
        height: 50%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column: 2;
        grid-row: 1;
        transform: scale(1);
        justify-self: center;
        .imgDescanso {
          grid-column: 1;
          grid-row: 1;
          justify-self: center;
          margin: 23% 0px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :first-child {
          width: 44%;
          align-self: start;
          justify-self: end;
          margin-top: 3%;
          margin-right: -16px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :last-child {
          margin-left: -16px;
          width: 39%;
          margin-top: 57%;
          align-self: end;
          justify-self: start;
          box-shadow: 0 0 50px 0 #00000075;
        }
      }
      .fraseDescanso1 {
        margin-top: -2%;
        grid-column: 2;
        grid-row: 2;
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .descansoUno {
    padding: 0;
    .fraseContainer {
      margin-top: 0px;
      font-size: 1.5rem;
      grid-template-columns: 10% 80% 10%;
      grid-template-rows: 2fr 0.6fr;
      .imagesDescanso {
        margin-top: 129px;
        // margin-top: 0;
        display: grid;
        height: 50%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column: 2;
        grid-row: 1;
        transform: scale(1);
        justify-self: center;
        .imgDescanso {
          grid-column: 1;
          grid-row: 1;
          justify-self: center;
          margin: -23% 0px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :first-child {
          width: 44%;
          align-self: start;
          justify-self: end;
          margin-top: -40%;
          margin-right: -16px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :last-child {
          margin-left: -16px;
          width: 39%;
          margin-top: 14%;
          align-self: inherit;
          justify-self: start;
          box-shadow: 0 0 50px 0 #00000075;
        }
      }
      .fraseDescanso1 {
        font-size: 1.1rem;
        margin-top: -10%;
        margin-top: 0;
        grid-column: 2;
        grid-row: 2;
        margin-bottom: 30px;
        text-align: center;
        justify-self: center;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .descansoUno {
    padding: 0;
    .fraseContainer {
      margin-top: 0px;
      font-size: 1.5rem;
      grid-template-columns: 10% 80% 10%;
      grid-template-rows: 2fr 0.6fr;
      grid-template-columns: 10% 40% 40% 10%;
      grid-template-rows: 1fr;
      align-items: center;
      height: 90vh;
      .imagesDescanso {
        margin-top: 129px;
        margin-top: 0;
        display: grid;
        height: 50%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column: 2;
        grid-row: 1;
        transform: scale(1);
        justify-self: center;
        .imgDescanso {
          grid-column: 1;
          grid-row: 1;
          justify-self: center;
          margin: -23% 0px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :first-child {
          width: 44%;
          align-self: start;
          justify-self: end;
          margin-top: -30%;
          margin-right: -16px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :last-child {
          margin-left: -16px;
          width: 39%;
          margin-top: 6%;
          align-self: inherit;
          justify-self: start;
          box-shadow: 0 0 50px 0 #00000075;
        }
      }
      .fraseDescanso1 {
        font-size: 1.1rem;
        margin-top: -10%;
        margin-top: 0;
        grid-column: 3;
        grid-row: 1;
        margin-bottom: 30px;
        text-align: end;
        justify-self: center;
      }
    }
  }
}

@media screen and (max-width: 667px) and (orientation: landscape) {
  .descansoUno {
    padding: 0;
    .fraseContainer {
      margin-top: 0px;
      font-size: 1.5rem;
      grid-template-columns: 10% 80% 10%;
      grid-template-rows: 2fr 0.6fr;
      grid-template-columns: 10% 40% 40% 10%;
      grid-template-rows: 1fr;
      align-items: center;
      height: 90vh;
      .imagesDescanso {
        margin-top: 129px;
        margin-top: 0;
        display: grid;
        height: 50%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column: 2;
        grid-row: 1;
        transform: scale(1);
        justify-self: center;
        .imgDescanso {
          grid-column: 1;
          grid-row: 1;
          justify-self: center;
          margin: -23% 0px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :first-child {
          width: 44%;
          align-self: start;
          justify-self: end;
          margin-top: -30%;
          margin-right: -16px;
          box-shadow: 0 0 50px 0 #00000075;
        }
        :last-child {
          margin-left: -16px;
          width: 39%;
          margin-top: 6%;
          align-self: inherit;
          justify-self: start;
          box-shadow: 0 0 50px 0 #00000075;
        }
      }
      .fraseDescanso1 {
        font-size: 1.1rem;
        margin-top: -10%;
        margin-top: 0;
        grid-column: 3;
        grid-row: 1;
        margin-bottom: 30px;
        text-align: center;
        justify-self: center;
      }
    }
  }
}

.descansoDos {
  // margin-top: 180px;
  padding: 280px 0px 0px;
  color: #333;
  background-color: rgb(235, 235, 235);
  display: grid;
  overflow: hidden;
  height: 44vh;
  .fraseContainerDos {
    // margin-top: -140px;
    grid-column-start: 1;
    grid-row-start: 1;
    text-align: left;
    display: grid;
    // grid-template-columns:  20% 60% 20%;
    justify-items: center;
    // width: 100%;
    max-width: 100vw;

    .fraseDescanso2 {
      grid-column: 2;
      font-size: 3rem;
      // margin-top: 30%;
      // margin-top: 50%;
      width: 70%;
    }
  }
  div {
    background: linear-gradient(
      145deg,
      #ffdbf1,
      #daf4f2 32%,
      #7dccf0 74%,
      #2013bb
    );
    background: linear-gradient(145deg, #888 32%, #06a8f2 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .circle {
    //   position: absolute;
  }

  .circleanimation {
    grid-column-start: 1;
    grid-row-start: 1;
    border: 5px solid #07a8f269;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    justify-self: end;
    align-self: start;
    margin-top: -369px;
    margin-right: -100px;
  }

  .circleanimation1 {
    grid-column-start: 1;
    grid-row-start: 1;
    border: 5px solid #07a8f269;
    width: 100px;
    height: 100px;
    border-radius: 1000px;
    justify-self: end;
    align-self: start;
    margin-top: -174px;
    margin-right: 95px;
  }
  .circleanimation2 {
    grid-column-start: 1;
    grid-row-start: 1;
    border: 1px solid #07a8f269;
    width: 1100px;
    height: 1100px;
    border-radius: 1000px;
    justify-self: end;
    align-self: start;
    margin-top: -667px;
    margin-right: -398px;
  }
  .circleanimation3 {
    grid-column-start: 1;
    grid-row-start: 1;
    border: 3px solid #07a8f269;
    width: 1500px;
    height: 1500px;
    border-radius: 1500px;
    justify-self: end;
    align-self: start;
    margin-top: -843px;
    margin-right: -568px;
  }
}

@media screen and (max-width: 1280px) and (orientation: landscape) {
  .descansoDos {
    padding: 117px 0 48px;
    height: 35vh;
    .fraseContainerDos {
      .fraseDescanso2 {
        font-size: 2.5rem;
        position: absolute;
        left: 85px;
      }
    }
  }
}

@media screen and (max-width: 1050px) and (orientation: landscape) {
  .descansoDos {
    padding: 55px 0 50px;
    .fraseContainerDos {
      .fraseDescanso2 {
        font-size: 2.2rem;
        position: absolute;
        left: 85px;
      }
    }
  }
}

@media screen and (max-width: 950px) and (orientation: landscape) {
  .descansoDos {
    padding: 55px 0 65px;
    .fraseContainerDos {
      .fraseDescanso2 {
        font-size: 1.8rem;
        position: absolute;
        left: 85px;
      }
    }
  }
}

@media screen and (max-width: 896px) and (orientation: landscape) {
  .descansoDos {
    padding: 50px 0 55px;

    .fraseContainerDos {
      .fraseDescanso2 {
        font-size: 1.8rem;
        position: absolute;
        left: 85px;
      }
    }
  }
}

@media screen and (max-width: 740px) and (orientation: landscape) {
  .descansoDos {
    padding: 50px 0 55px;
    .fraseContainerDos {
      .fraseDescanso2 {
        font-size: 1.5rem;
        position: absolute;
        left: 85px;
      }
    }
  }
}

@media screen and (max-width: 667px) and (orientation: landscape) {
  .descansoDos {
    padding: 50px 0 48px;
    .fraseContainerDos {
      .fraseDescanso2 {
        font-size: 1.5rem;
        position: absolute;
        left: 85px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .descansoDos {
    padding-top: 50px;
    height: 50vh;
  }
}

@media screen and (max-width: 500px) {
  .descansoDos {
    padding: 50px 0 75px;

    div {
      background: linear-gradient(145deg, #888 2%, #06a8f2 74%);
      -webkit-background-clip: text;
    }
    .fraseContainerDos {
      width: 100vw;
      .fraseDescanso2 {
        font-size: 23px;
      }
    }
  }
}
