$brightColor: #aaa;
$medColor: #333;
$darkColor: #222;
$blueColor: #009DEF;


body{
    background-color: $darkColor;
}


    .titulo{
        font-size: 3em;
        color: $brightColor;
        display: grid;
        justify-content: center;
        margin-top: 60px;

        .tituloFlecha{
            img{
                width: 38px;
            }
    
        }
    }


    @media screen and (max-width: 600px) {
        .titulo{
            font-size: 1.5em;
            .tituloFlecha{ 
                img{
                    width: 25px;
                }
            }
        }
    }


    @media screen and (max-width: 800px) {
        .titulo{
            font-size: 1.5em;
            .tituloFlecha{ 
                img{
                    width: 25px;
                }
            }
        }
    }

