$brightColor: #aaa;
$medColor: #666;
$darkColor: #222;
$blueColor: #009def;

.fixedMenu {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 100%;
  z-index: 10000;
  pointer-events: none;
}

.logoHam {
  display: flex;
  grid-row: 1;
  grid-column: 1;
  justify-self: start;
  align-self: start;
  margin: 4%;
  align-items: center;
  pointer-events: all;
}

.logo3bh {
  width: 190px;
  grid-row: 1;
  grid-column: 1;
  pointer-events: all;
  margin-right: 10px;
  margin-right: 30px;
}

.idiomas {
  color: $brightColor;
  grid-row: 1;
  grid-column: 2;
  justify-self: end;
  align-self: start;
  margin: 9%;
  a {
    color: $brightColor;
  }
}

.mute {
  grid-row: 2;
  grid-column: 1;
  justify-self: start;
  align-self: end;
  margin: 9%;
}

.redes {
  grid-row: 2;
  grid-column: 2;
  justify-self: end;
  align-self: end;
  margin: 9%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: end;
  .redesSize {
    width: 40px;
  }
  .face {
    pointer-events: all;
    text-align: right;
    margin: 10px 0px;
    grid-row: 1;
    grid-column: 1;
    justify-self: right;
  }

  .insta {
    pointer-events: all;
    margin: 10px 0px;
    grid-row: 2;
    grid-column: 1;
    justify-self: right;
  }
}
.hamMenuBtn:hover:after {
  content: ' ';
  width: 30px;
  height: 30px;
  margin-left: 3px;
  background-color: transparent;
  background-image: url(./../../assets/iconos/flecha.svg);
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: 'FlipV';
}

.hamMenu {
  .redes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: start;
    margin: 0;
    // gap: 20px;
    // margin-left: -45px;
    margin-top: 32px;

    img {
      width: 28px;
    }
    .face {
      text-align: left;
    }
    .insta {
      // width: 38px;
    }
  }
}
.hamCircle {
  border-radius: 100%;
  background-color: grey;
  background-color: $medColor;
  width: 60px;
  height: 60px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  transition: 0.5s;
  margin-left: -190px;
  pointer-events: all;
  // &:hover{
  //   width: 110vw;
  //   height: 110vw;

  //   place-self: center;
  //   justify-self: center;
  //   align-self: center;

  //   margin: -36% -22%;

  // }

  // position: absolute;
  // margin: 0 0 0 186px;
  // display: block;
}

.hamLines {
  margin: 3px 0;
  border-radius: 2px;
  background-color: white;
  width: 25px;
  height: 3px;
}

.hamX {
  border-radius: 100%;
  background-color: grey;
  background-color: $darkColor;
  width: 60px;
  height: 60px;
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  transition: 0.5s;
  // margin-left: -190px;
  pointer-events: all;
  :first-child {
    grid-row-start: 1;
    grid-column-start: 1;
    transform: rotateZ(135deg);
  }
  :last-child {
    transform: rotateZ(-135deg);
    grid-row-start: 1;
    grid-column-start: 1;
  }
  .hamLines:hover {
    background-color: $blueColor;
  }
}

.contenedorHamMenu {
  pointer-events: none;
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background-color: $darkColor;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 10000;
  .hamMenu {
    pointer-events: all;
    z-index: 2;
    background-color: $darkColor;
    display: flex;
    width: 3000px;
    height: 3000px;
    border-radius: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    // position: absolute;
    // top: -50vh;
    // left: 0;

    // transition: 1s;
    // width: 0.1vw;
    // height: 0.1vw;
    // width: 20.1vw;
    // height: 20.1vw;
    overflow: hidden;

    .btnFlecha {
      // margin: 13px 0;
      // align-self: center;
      // align-items: baseline;
      // margin: 31px 0;
      // align-self: center;
      // align-items: baseline;
      font-size: xxx-large;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.menuDesign {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 250px;
}

.contenedorBtns {
  width: 100%;
  display: grid;
  gap: 40px;
}

.my-modal-veil {
  background-color: black;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

body {
  /* Cursor styles */
  --cursor-stroke: rgb(117, 117, 117);
  --cursor-fill: none;
  --cursor-stroke-width: 1px;
}

.cursor {
  display: none;
}

.no-scroll {
  overflow: hidden !important;
}

@media screen and (max-width: 723px) {
  .menu {
    .fixedMenu {
      .logoHam {
        background-color: black;
        width: 99.5vw;
        // padding: 5% 0 0% 6%;
        margin: 0;
        box-shadow: 0 0 63px 60px black;
        .logo3bh {
          margin: 20px;
          width: 100px;
        }
        .hamCircle {
          transform: translate(170px, 0) scale(0.7) !important;
        }
      }
      .redes {
        // display: none;
        grid-column: 1;
        margin: 0 3% 1% 0;
        .redesSize {
          width: 30px;
        }
      }
      .idiomas {
        display: none;
      }
      .mute {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .menu {
    .fixedMenu {
      .logoHam {
        background-color: transparent;
        width: 99.9vw;
        // padding: 5% 0 0% 6%;
        margin: 0;
        box-shadow: 0 0 63px 60px rgba(0, 0, 0, 0);
        .logo3bh {
          width: 150px;
        }
        .hamCircle {
          transform: translate(170px, 0) scale(0.7) !important;
        }
      }
      .redes {
        // display: none;
        grid-column: 1;
        // margin: 0 14% 9% 0;
      }
      .idiomas {
        display: none;
      }
      .mute {
        display: none;
      }
    }
  }
}
