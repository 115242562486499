$brightColor: #aaa;
$medColor: #333;
$darkColor: #222;
$blueColor: #009def;

.txtTech {
  color: $brightColor;
}

.mobile__homePage {
  overflow-x: hidden;
}

.mobile__contenedorProjects {
  .parrafos {
    display: none;
  }
  .txtTech {
    display: none;
  }
  align-items: center;
  align-items: flex-start;
  align-content: center;
  color: $brightColor;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 120px;
  // height: 90vh;
  justify-content: flex-start;
  // transform: scale(0.55);
  flex-direction: column;
  // margin-top: -100px;
}

.mobile__projects {
  display: flex;
  gap: 0px;
  margin: 0 130px;
  gap: 25px;
  //   width: 90%;
}

.mobile__cp1 {
  margin-top: -35px;
}

.mobile__cp2 {
  margin-top: -350px;
}

.mobile__proPic {
  width: 328px;
  height: 286px;
  overflow: hidden;
  align-self: center;
  img {
    box-shadow: 0 0 200px 11px #0000001f;
    box-shadow: 0 0 10px 0px #0000001f;
    max-width: 500px;
  }
}

.mobile__proV1 {
  margin-top: -170px;
}

.mobile__groupPartner {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 100px;
  }
}

.mobile__proTitulo {
  text-align: left;
  font-size: 4.5rem;
  margin: 0;
}

.mobile__proInfo {
  display: flex;
  align-self: flex-end;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  align-items: flex-start;
  width: 55vw;
  .btnFlecha {
    align-self: center;
    align-self: start;
    font-size: 2em;
  }
}

.mobile__btnFlecha {
  align-self: end;
  font-size: 2.5em;
}

.mobile__centrarBtn {
  display: grid;
  justify-content: center;
  padding: 0 0 120px 0;
  margin: -50px 0 10px 0;
  .btnFlecha {
    font-size: 2em;
  }
}

.mobile__spaceProjects {
  overflow-x: clip;
  transition: top 0.5s;
  top: 0;
  .titulo {
    margin: 30px 0 50px;
    h1 {
      margin: 0;
    }
  }
  .parrafo {
    display: none;
  }
}
.mobile__proDescripcion {
  text-align: left;
}
@media screen and (max-width: 1100px) {
  .mobile__proInfo {
    width: 67%;
  }
  .mobile__proPic {
    margin-right: 20px;
  }
  .mobile__projects {
    display: flex;
    gap: 0px;
    margin: 0 30px;
  }
}

@media screen and (max-width: 820px) and (orientation: portrait) {
  .mobile__proInfo {
    width: 50%;
  }
  .mobile__proTitulo {
    font-size: 40px;
  }
  .mobile__proPic {
    margin-right: 20px;
    place-self: start;
  }
  .mobile__projects {
    display: flex;
    gap: 0px;
    margin: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .mobile__proInfo {
    width: 44%;
  }

  .mobile__groupPartner {
    flex-wrap: wrap;
  }
  .mobile__proTitulo {
    text-align: left;
    font-size: 3rem;
  }
  .mobile__proPic {
    margin-right: 20px;
    align-self: start;
  }
  .mobile__projects {
    display: flex;
    gap: 0px;
    margin: 0 30px;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .mobile__projects {
    justify-content: center;
    .mobile__proPic {
      width: 273px;
      height: 243px;
      img {
        max-width: 349px;
      }
    }
    .mobile__proInfo {
      align-self: flex-start;
      width: 57%;
      .mobile__proTitulo {
        font-size: 2rem;
      }
    }
  }
  .mobile__groupPartner {
    display: flex;
    // display: none;
    flex-wrap: wrap;
    margin: 22px 0;
    img {
      width: 60px;
    }
  }

  .mobile__spaceProjects {
    .titulo {
      margin: 65px 0 50px;
      h1 {
        font-size: 1.4em;
        margin: 0 0 30px;
      }
    }
  }
}

@media screen and (max-width: 667px) and (orientation: landscape) {
  .mobile__projects {
    .mobile__proPic {
      width: 273px;
      height: 243px;
      img {
        max-width: 349px;
      }
    }
  }
  .mobile__proInfo {
    align-self: flex-start;
  }
  .mobile__groupPartner {
    display: flex;
    display: none;
    flex-wrap: wrap;
    margin: 22px 0;
    img {
      width: 60px;
    }
  }
}

@media screen and (max-width: 540px) {
  .mobile__spaceProjects {
    .titulo {
      margin: 65px 0 50px;
      h1 {
        margin: 0;
      }
    }
  }

  .mobile__homePage {
    overflow-x: hidden;
  }

  .mobile__contenedorProjects {
    color: $brightColor;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    // flex-direction: row;
    gap: 60px;
  }

  .mobile__projects {
    width: 100vw;
    display: flex;
    gap: 0px;
    margin: 0 0px;
    flex-direction: column;
  }

  .mobile__cp1 {
    margin-top: -35px;
    margin-top: 5px;
  }

  .mobile__cp2 {
    margin-top: -350px;
    margin-top: 5px;
  }

  .mobile__proPic {
    width: 75vw;
    height: 64vw;
    overflow: hidden;
    display: grid;
    justify-content: center;
    align-content: center;
    margin-right: 0;
    img {
      box-shadow: 0 0 200px 11px #0000001f;
      box-shadow: 0 0 10px 0px #0000001f;
      max-width: 500px;
    }
  }

  .mobile__proV1 {
    margin-top: -170px;
  }

  .mobile__groupPartner {
    display: flex;
    flex-wrap: wrap;
    margin: 22px 0;
    img {
      width: 60px;
    }
  }

  .mobile__proTitulo {
    font-size: 3.5rem;
    font-size: 2.2rem;
    text-align: left;
    margin: 5px 0 0 0;
  }

  .mobile__proDescripcion {
    line-height: 2;
    text-align: left;
  }

  .mobile__proInfo {
    // padding: 0 20px;
    // padding: 0 28px;
    display: flex;
    align-self: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 50px;
    width: 75%;
  }

  .mobile__btnFlecha {
    align-self: start;
    font-size: 1.5em;
  }

  .mobile__centrarBtn {
    display: grid;
    justify-content: center;
    padding: 0 0 120px 0;
    margin: 50px 0 10px 0;
    .mobile__btnFlecha {
      font-size: 1em;
    }
  }

  .mobile__spaceProjects {
    overflow-x: clip;
    transition: top 0.5s;
    top: 0;
  }
}
