.video3bh {
  div {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    // background-color: rgba(255, 0, 0, 0.363);

    .videoMobile {
      grid-column-start: 1;
      grid-row-start: 1;
      max-height: 100vh;
      height: 100%;
      align-self: start;
      video {
        // z-index: -1;
        // width: 100%;
        // max-height: 100vh;
        grid-column-start: 1;
        grid-row-start: 1;
        max-height: 100vh;
        height: 70% !important;
        align-self: start;
        width: auto !important;
      }
    }

    video {
      grid-column-start: 1;
      grid-row-start: 1;
      height: 100vh;
      width: auto;
    }
    // }
  }
}

.audifonos {
  justify-self: center;
  align-self: end;
  // width: 150px;
  width: 12rem;
  height: 12rem;
  border: 0;
  margin-top: -75px;
  border: none;
  background-color: transparent;
  // position: absolute;
  cursor: pointer;
  background-color: #00000054;
  padding: 30px;
  border-radius: 50%;
  // z-index: 1000;
  // :active{
  //   border: 0;
  //   outline: none;
  // }
  // :visited{
  //   border: 0;
  //   outline: none;
  // }
  img {
    width: 6rem;
  }

  p {
    margin-top: 10px;
    font-size: 1rem;
    color: rgb(236, 236, 236);
  }
}

.audifonos:focus {
  border: 0;
  outline: none;
}

.muter {
  transition: 3s;
  display: none;
  justify-self: center;
  align-self: end;
  // width: 150px;
  width: 12rem;
  height: 12rem;
  border: 0;
  margin-top: -75px;
  border: none;
  background-color: transparent;
  // position: absolute;
  cursor: pointer;
  background-color: #00000054;
  padding: 30px;
  border-radius: 50%;
  // z-index: 1000;
  opacity: 0.1;
  &:hover {
    opacity: 1;
  }
  img {
    width: 6rem;
  }

  p {
    margin-top: 10px;
    font-size: 1rem;
    color: rgb(236, 236, 236);
  }
}

.goDown {
  align-self: end;
  margin-bottom: 10vh;
  color: #b3b3b3;
  // text-transform: uppercase;
  text-decoration: none;
  &::after {
    animation-duration: 4s;
    animation-name: upDown;
    animation-iteration-count: infinite;
    content: ' | ';
    // background-color: white;
    width: 90px;
    height: 30px;
    position: absolute;
    margin-left: -53px;
    margin-top: 19px;
    // background-image: url("../../assets/iconos/downArrow.svg");
    background-repeat: no-repeat;
    // opacity: 0.5;
    background-size: 20px;
    margin-left: 1px;
    // bottom: 6vh;
  }
}

// @keyframes upDown {
//     0% { margin-top: 30px;}
//     10% { margin-top: 39px; }
//     20% { margin-top: 30px; }
//     30% { margin-top: 39px; }
//     40% { margin-top: 30px; }
//     100% { margin-top: 30px; }
//   }

//   @keyframes upDown {
//     0% { margin-top: 20px;}
//     10% { margin-top: 34px; }
//     20% { margin-top: 20px; }
//     30% { margin-top: 34px; }
//     40% { margin-top: 20px; }
//     100% { margin-top: 20px; }
//   }

@keyframes upDown {
  0% {
    margin-top: 65px;
  }
  10% {
    margin-top: 72px;
  }
  20% {
    margin-top: 65px;
  }
  30% {
    margin-top: 72px;
  }
  40% {
    margin-top: 65px;
  }
  100% {
    margin-top: 65px;
  }
}

.btnContenedor {
  grid-column-start: 1;
  grid-row-start: 1;
  display: grid;
  grid-column-start: 1;
  grid-row-start: 1;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
  // background: #00000045;
  z-index: 1000;
  opacity: 0.5;

  // top: 0;
  // left: 0;
  width: 100%;
  // height: 100vh;
  // position: absolute;
  // z-index: 0;
  max-height: 100vh;
  // display: grid;
  display: grid;
  justify-content: center;
  // justify-content: normal;
  justify-items: center;
  align-items: center;
  background-color: rgba(17, 17, 17, 0.363);
  overflow: hidden;

  &:hover {
    opacity: 1;
    .muter {
      opacity: 0.2;
    }
  }
}

.loading {
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 80003;
  justify-content: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  top: 12px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #06a8f2;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

////////////// 1023

@media screen and (max-width: 1030px) {
  .video3bh {
    overflow-x: hidden;
    width: 100%;
    .btnContenedor {
      justify-content: normal !important;
      justify-items: center;
      padding: 0;
      .audifonos {
        margin-top: 5px;
        transform: scale(0.7);
      }

      .muter {
        margin-top: 5px;
        transform: scale(0.8);
      }
    }
    div {
      width: 100vw;
      .videoMobile {
        video {
          height: 100% !important;
          // width: auto;
          width: 100% !important;
          // height: auto !important;
        }
      }
    }
  }
}

// @media screen and (max-width: 723px) {
//     .video3bh{
//         // padding: 90px 0;
//         video{

//         }

//     }
// }

/////////////////////////////////////////////////// 823

@media screen and (max-width: 823px) {
  .video3bh {
    overflow-x: hidden;
    width: 100%;
    .btnContenedor {
      justify-content: normal !important;
      justify-items: center;
      padding: 0;
      align-content: center;
      .audifonos {
        margin-top: 5px;
        transform: scale(0.7);
      }

      .muter {
        margin-top: 5px;
        transform: scale(0.8);
      }
    }
    div {
      width: 100vw;
      .videoMobile {
        video {
          // height: 100% !important;
          // width: auto;
          width: 100%;
          height: 100% !important;
        }
      }
    }
  }
}

// ////////////////////////////////////////////////////end 823

////////////////////////////////////////////// 569

// // en este caso primero mas chico luego mas grande
// @media screen and (max-width: 569px) {
//     .video3bh{
//         width: 10%;
//     }
//     .btnContenedor{
//         .audifonos{
//             transform: scale(0.5)
//         }
//     }
// }

// @media screen and (max-width: 400px) {
//     .video3bh{
//         width: 10%;
//     }
//     .btnContenedor{
//         .audifonos{
//             transform: scale(0.7)
//         }
//     }
// }

@media screen and (max-width: 569px) {
  .video3bh {
    overflow-x: hidden;
    width: 100%;
    .btnContenedor {
      justify-content: normal !important;
      justify-items: center;
      align-content: center;
      .audifonos {
        margin-top: 0px;
        transform: scale(0.6);
        margin-bottom: -10px;
      }

      .muter {
        margin-top: 0px;
        transform: scale(0.8);
      }
    }
    div {
      width: 100vw;
      .videoMobile {
        video {
          height: auto !important;
          width: 95% !important;
          justify-self: center;
          align-self: center;
        }
      }
    }
  }
}

///////////////////////////////////////////////// end 569

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .video3bh {
    overflow-x: hidden;
    width: 100%;
    .btnContenedor {
      align-content: center;
      .audifonos {
        margin-top: 5px;
        transform: scale(0.6);
      }

      .muter {
        margin-top: -5px;
        transform: scale(0.65);
      }
    }
    div {
      width: 100vw;
      .videoMobile {
        video {
          height: 100% !important;
          width: auto !important;
        }
      }
    }
  }
}

/////////////////////////////////////////// min 414 max 767

@media only screen and (min-device-width: 414px) and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .video3bh {
    overflow-x: hidden;
    width: 100%;
    .btnContenedor {
      .audifonos {
        margin-top: 5px;
        transform: scale(0.6);
      }

      .muter {
        margin-top: -5px;
        transform: scale(0.65);
      }
    }
    div {
      width: 100vw;
      .videoMobile {
        video {
          height: 100% !important;
          width: auto !important;
        }
      }
    }
  }
}
/////////////////////////////////////////// end min 414 max 767

.loader {
  max-width: 40vw;
  margin-bottom: 80px;
}

@media screen and (min-width: 1200px) {
  .loader {
    max-width: 30vw;
    margin-bottom: 80px;
  }
}
