$brightColor: #aaa;
$medColor: #333;
$darkColor: #222;
$blueColor: #009DEF;


.btnFlecha{
    color: $brightColor;
    display: flex;
    text-decoration: none;
    align-items: baseline;
    justify-content: flex-start;
    justify-items: flex-end;
    font-size: 3em;
    cursor: pointer;
   
    svg{
        transform: rotateX(180deg);
        height: inherit;
        height: 15px;
        width: 15px;
        margin-left: 5px;
      }
    img{
        transform: rotateX(180deg);
        height: inherit;
        height: 15px;
        margin-left: 5px;
    }
}