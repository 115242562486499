$brightColor: #aaa;
$medColor: #666;
$darkColor: #222;
$blueColor: #009DEF;

.footer{
    padding: 4.5%;
    height: 60vh;
    width: 91%;
    z-index: 10001;
    position: absolute;
    background-color: black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    
    .btnFooter{
        grid-column-start: 1;
        grid-row-start: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  
        grid-gap: 80px;
        grid-row-gap: 30px;
        // margin: 8.5%;
        .btnFlecha{
            font-size: 1.8em;
            color: #ddd;
            align-items: baseline;
            height: inherit;
        }
    }
    .logoFooter{
        // margin: 8.5%;
        grid-column-start: 1;
        grid-row-start: 3;
        color: white;
        justify-self: start;
        width: 500px;
        align-self: end;
    }

    .adress{
        grid-column-start: 2;
        grid-row-start: 2;
        color: white;
        text-align: right;
        font-size: 1.3rem;
        line-height: 1;
        align-self: start;
        justify-self: end;
        a{
            color: white;
            text-decoration: none;
            :first-child{
    
                &::before{
                    content: ' ';
                    width: 90px;
                    height: 29px;
                    position: absolute;
                    margin-left: -30px;
                    margin-top: -2px;
                    background-image: url("../../assets/iconos/pin.svg");
                    background-repeat: no-repeat;
                }
            }
        }

    }
    .contactoFooter{
        grid-column-start: 2;
        grid-row-start: 1;
        color: white;
        font-size: 3em;
        justify-self: end;
        // margin-right: 100px;
        &::before{
            content: ' ';
            // background-color: white;
            width: 90px;
            height: 40px;
            position: absolute;
            margin-left: -70px;
            margin-top: 20px;
            background-image: url("../../assets/iconos/sobre.svg");
            background-repeat: no-repeat;
        }
        &::after{
            content: ' ';
            width: 8.16em;
            height: 0.1em;
            position: absolute;
            background-color: #009DEF;
            margin: 1.1em 0 0 -8.15em;
        }
        a{
            text-decoration: none;
            color: white;
        }
    }
    .infoFooter{
        display: grid;
        grid-column-start: 2;
        grid-row-start: 3;
        justify-self: end;
        align-self: end;
        // margin-right: 100px;
        .redesFooter{
            .redes{
                display: flex;
                place-content: flex-end;
                gap: 1em;
                margin: 0;
                margin-bottom: 20px;
                a{
                    width: 55px;
                }
                img{
                    width: 55px;
                }
            }
        }
        .legalesFooter{
            color: white;
            display: flex;
            gap: 20px;
            align-items: baseline;
            p{
                margin: 0;
            }
        }
    }

}

@media screen and (max-width: 1368px) and (orientation: landscape) {
    .footer{
        .logoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 4;
            margin: 0px 0;
            width: 500px;
        }
        .contactoFooter{
            grid-row-start: 1;
        }
        .adress{
            grid-column-start: 2;
            grid-row-start: 2;
            justify-self: end;
            align-self: end;
            text-align: right;
        }
        .infoFooter{
            grid-row-start: 4;

        }
    }
}





@media screen and (max-width: 1170px) {
    .footer{
        grid-template-columns: 1fr;
        height: auto;
        .contactoFooter{
            grid-column: 1;
            grid-row: 2;
            justify-self: center;
            margin: 70px 0;
            margin-left: 90px;

        }
   
        .logoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 1;
            margin: 70px 0;
        }
        .infoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 3;
            .redesFooter{
                display: grid;
                justify-content: center;
                .redes{
                    display: grid;
                    justify-content: center;
                    margin: 80px auto 40px auto;
                    place-content: flex-start;
                    grid-template-rows: 1fr;
                    .face{
                        grid-column: 1;
                        grid-row: 1;
                    }
                    .insta{
                        grid-column: 2;
                        grid-row: 1;

                    }
                    a{
                        text-align: center;
                        
                    }
                    img{
                        width: 50px;
                    }
                }
            }
        }
  }
}


@media screen and (max-width: 1200px) and (orientation: landscape) {
    .footer{
        .logoFooter{
            justify-self: start;
            grid-column-start: 1;
            grid-row-start: 4;
            margin: 0px 0;
            width: 400px;
        }
        .contactoFooter{
            grid-row-start: 1;
            grid-column: 2;
            margin: 0;
            margin-left: 0;   
            font-size: 2em;
            &::before{
                width: 54px;
                height: 29px;
                position: absolute;
                margin-left: -54px;
                margin-top: 10px;
            }      
        }
        .adress{
            grid-column-start: 2;
            grid-row-start: 2;
            justify-self: end;
            align-self: end;
            text-align: right;
        }
        .infoFooter{
            grid-row-start: 4;
            grid-column-start: 2;
            justify-self: end;
        }
    }
}





@media screen and (max-width: 917px) {
    .footer{
        .adress{
            grid-column-start: 1;         
            text-align: center;
            margin-left: 49px;

            justify-self: center;
        }
        .contactoFooter{
            margin: 0;
            margin-left: 49px;
            align-self: end;
            font-size: 2.5em;
            &::before{
                margin-top: 10px;
            }
        }
        .logoFooter{
            width: 350px;
        }
    }
}

@media screen and (max-width: 600px) {
    .footer{
        border-top: solid 1px $blueColor;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        height: auto;

        .btnFooter{
            margin: 20px 0;
            gap: 30px;
            justify-self: start;
            .btnFlecha{
                font-size: 1.5em;
                svg{
                    height: 9px;
                    width: 9px;
                }
            }

        }

        .contactoFooter{
            margin: 40px 0 40px 43px;
            font-size: 1.5em;
            grid-column-start: 1;
            grid-row-start: 2;
            justify-self: start;
            margin: 108px 0 0;

            &::after{
                // margin-top: 27px;
            }
            &::before{
                margin-top: 8px;
                width: 40px;
                height: 70px;
                margin-left: -43px;
            }
        }
        .logoFooter{
            grid-column-start: 1;
            grid-row-start: 1;
            justify-self: center;
            width: 80%;
            margin-top: 30px;

        }
        .infoFooter{
            grid-column-start: 1;
            grid-row-start: 4;
            justify-self: center;
            .legalesFooter{
                margin-bottom: 10px;
                flex-direction: column;
                align-items: center;
                p:nth-child(2){
                    display: none;
                }
                p:nth-child(4){
                    display: none;
                }
            }
            .redesFooter{
                .redes{
                    margin: 60px auto 20px auto;
                    place-content: flex-start;
                    a{
                        text-align: left;

                    }
                    img{
                        width: 20px;
                    }
                    .face{
                        text-align: center;
                    }
                    .insta{
                        text-align: center;
                    }
                }
            }
        }
// checkMEDIA
        .adress{
            grid-column-start: 1;         
            text-align: center;
            margin-left: 49px;

            justify-self: center;
        }
        .contactoFooter{

            margin: 108px 0 0;
            margin-left: 116px;
            align-self: end;
            font-size: 2em;
            &::before{
                margin-top: 10px;
            }
        }
        .logoFooter{
            width: 350px;
        }
    }

  }

  @media screen and (max-width: 667px) and (orientation: landscape) {
    .footer{
        border-top: solid 1px $blueColor;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        height: auto;
        .logoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 1;
            margin: 70px 0;
            margin: 28px 0;
            width: 299px;
        }
        .contactoFooter{
            grid-row-start: 2;
            justify-self: center;
        }
        .adress{
            grid-column-start: 1;
            grid-row-start: 3;
            justify-self: center;
            text-align: center;
        }
        .infoFooter{
            grid-row-start: 4;
            justify-self: center;
        .redesFooter .redes a {
                text-align: center;
            }

        }
    }

    .footer{
        border-top: solid 1px $blueColor;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        height: auto;
        .logoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 3;
            margin: 70px 0;
            margin: 28px 0 0;
            width: 299px;
        }
        .contactoFooter{
            grid-row-start: 1;
            justify-self: end;
            font-size: 2em;
            &::before{
                width: 58px;
                height: 40px;
                position: absolute;
                margin-left: -65px;
                margin-top: 8px;}
        }
        .adress{
            grid-column-start: 2;
            grid-row-start: 2;
            justify-self: end;
            text-align: right;
        }
        .infoFooter{
            grid-row-start: 3;
            justify-self: right;
        .redesFooter{
            justify-content: end;
            .redes {
                margin: 0;
                a {
                    text-align: center;
                }
            }
        }

        }
    }
}




@media screen and (max-width: 500px) and (orientation: landscape) {
    .footer{
        border-top: solid 1px $blueColor;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        height: auto;
        .logoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 1;
            margin: 70px 0;
            margin: 28px 0;
            width: 255px;
        }
        .contactoFooter{
            grid-row-start: 2;
            justify-self: center;
            // &::before{
            //     width: 54px;
            //     height: 29px;
            //     position: absolute;
            //     margin-left: -54px;
            //     margin-top: 10px;
            // }
        }
        .adress{
            grid-column-start: 1;
            grid-row-start: 3;
            justify-self: center;
            text-align: center;
        }
        .infoFooter{
            grid-row-start: 4;
            justify-self: center;
            margin: 31px 0 0 0;
            .legalesFooter{
                margin-bottom: 0;
            }
        .redesFooter{
            justify-content: center;
            .redes a {
                    text-align: center;
                }
        } 

        }
    }
}


  @media screen and (max-width: 400px) {
    .footer{
        .logoFooter{
            justify-self: center;
            grid-column-start: 1;
            grid-row-start: 1;
            margin: 70px 0;
            width: 300px;
        }

        .adress{
            grid-column: 1;
            grid-row: 2;
            text-align: center;
            justify-self: center;
            margin-left: 0;
            p{
                margin-left: 32px;
                margin-top: 0;
            }
        }
        .contactoFooter{
            justify-self: center;
            margin-left: 53px;
            &::before{
                margin-top: 15px;
            }
        }
        .infoFooter{
            justify-self: center;
            grid-column: 1;
            grid-row: 5;
            .redesFooter{
                display: grid;
                justify-content: center;
                .redes{
                    display: grid;
                    justify-content: center;
                    margin: 80px auto 40px auto;
                    place-content: flex-start;
                    a{
                        text-align: left;
                        
                    }
                    img{
                        width: 37px;
                    }
                }
            }
        }
  }
}


// footer contacto sobre margin top 8 a partir de 1418 de pantalla y los botones el gap bajarlo a 30