
.sectionInstagram{
    margin-top: 60px;
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    
    .timeInstagram{
    grid-column-start: 2;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    justify-items: center;
    gap: 20px;
    margin-bottom: 100px;
   
    .social3bh{
      color: #ffffff;
      align-self: center;
      justify-self: center;
      font-size: 30px;

    }
    .timeInstagram > div{  
      display: grid;
     
    }
    
    .instaImage{
      justify-self: center;
      width: auto;
      height: 440px;
      box-shadow: 1px 1px 0px 0px #25252525;
      // transform: scale(1);
      transition: 0.5s;
    } 

    .instaImage:hover{
      transform: scale(1.05);
      box-shadow: 10px 12px 250px 0px #34343425;
      z-index: 5000;
    }
  }



}

.grid1{
  grid-column-start: 1;
}

.grid2{
  grid-column-start: 2;
}

.grid3{
  grid-column-start: 1;
}

.grid4{
  grid-column-start: 2;
}

.grid5{
  grid-column-start: 1;
}

.grid6{
  grid-column-start: 3;
}

.grid7{
  grid-column-start: 2;
}

.grid8{
  grid-column-start: 3;
}

.grid9{
  grid-column-start: 1;
}

.grid0{
  grid-column-start: 3;
}

.grid10{
  grid-column-start: 4;
}

.grid11{
  grid-column-start: 2;
}

.grid12{
  grid-column-start: 3;
}

.grid13{
  grid-column-start: 2;
}

.grid14{
  grid-column-start: 3;
}

.grid15{
  grid-column-start: 4;
}

.grid16{
  grid-column-start: 1;
}

.grid17{
  grid-column-start: 3;
}

.grid18{
  grid-column-start: 2;
}

.grid19{
  grid-column-start: 3;
}

.grid20{
  grid-column-start: 1;
}

.grid21{
  grid-column-start: 4;
}

.grid22{
  grid-column-start: 1;
}

.grid23{
  grid-column-start: 3;
}


.grid24{
  grid-column-start: 1;
}

.grid25{
  grid-column-start: 2;
}

.grid26{
  grid-column-start: 1;
}

.grid27{
  grid-column-start: 2;
}

.grid28{
  grid-column-start: 1;
}

.grid29{
  grid-column-start: 3;
}

.grid30{
  grid-column-start: 2;
}

.grid31{
  grid-column-start: 3;
}

.grid32{
  grid-column-start: 1;
}

.grid33{
  grid-column-start: 3;
}

.grid34{
  grid-column-start: 4;
}

.grid35{
  grid-column-start: 2;
}

.grid36{
  grid-column-start: 3;
}

.grid37{
  grid-column-start: 2;
}

.grid38{
  grid-column-start: 3;
}

.grid39{
  grid-column-start: 4;
}

.grid40{
  grid-column-start: 1;
}

.grid41{
  grid-column-start: 3;
}

.grid42{
  grid-column-start: 2;
}

.grid43{
  grid-column-start: 3;
}

.grid44{
  grid-column-start: 1;
}

.grid45{
  grid-column-start: 4;
}

.grid46{
  grid-column-start: 1;
}

.grid47{
  grid-column-start: 3;
}


.timeInstagram div {
  // max-width: 360px;
  // max-height: 360px;
  // overflow: hidden;
  display: grid;
  width: 345px;
  height: 345px;
  overflow: hidden;
  transition: 0.5s;
  justify-content: center;

}

.timeInstagram div:hover{
  transform: scale(1.05);
  box-shadow: 0px 0px 130px 0px #0000009e;
  z-index: 5000;
}









@media screen and (max-width: 1370px) {
  .sectionInstagram{
    justify-content: center;
    .timeInstagram{
      grid-column-start: 2;
      grid-column-end: 3;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-columns: auto;

      justify-items: center;
      gap: 20px;
      margin-bottom: 100px;
      
        .instaImage{
          width: auto;
          height: 400px;
      
        } 
    }

  }

  .timeInstagram div {
    // max-width: 360px;
    // max-height: 360px;
    // overflow: hidden;
    display: grid;
    width: 320px;
    height: 320px;
    overflow: hidden;
    transition: 0.5s;
    justify-content: center;

  }


  .grid1{
    grid-column-start: 1;
  }
  
  .grid2{
    grid-column-start: 2;
  }
  
  .grid3{
    grid-column-start: 1;
  }
  
  .grid4{
    grid-column-start: 2;
  }
  
  .grid5{
    grid-column-start: 1;
  }
  
  .grid6{
    grid-column-start: 3;
  }
  
  .grid7{
    grid-column-start: 2;
  }
  
  .grid8{
    grid-column-start: 3;
  }
  
  .grid9{
    grid-column-start: 1;
  }
  
  .grid0{
    grid-column-start: 3;
  }
  

  .grid10{
    grid-column-start: 2;
  }

  .grid11{
    grid-column-start: 3;
  }

  .grid12{
    grid-column-start: 2;
  }

  .grid13{
    grid-column-start: 3;
  }

  .grid14{
    grid-column-start: 1;
  }

  .grid15{
    grid-column-start: 3;
  }

  .grid16{
    grid-column-start: 1;
  }

  .grid17{
    grid-column-start: 2;
  }

  .grid18{
    grid-column-start: 2;
  }

  .grid19{
    grid-column-start: 3;
  }

  .grid20{
    grid-column-start: 1;
  }

  .grid21{
    grid-column-start: 2;
  }

  .grid22{
    grid-column-start: 1;
  }

  .grid23{
    grid-column-start: 2;
  }

  .grid24{
    grid-column-start: 1;
  }
  
  .grid25{
    grid-column-start: 2;
  }
  
  .grid26{
    grid-column-start: 1;
  }
  
  .grid27{
    grid-column-start: 2;
  }
  
  .grid28{
    grid-column-start: 1;
  }
  
  .grid29{
    grid-column-start: 3;
  }
  
  .grid30{
    grid-column-start: 2;
  }
  
  .grid31{
    grid-column-start: 3;
  }
  
  .grid32{
    grid-column-start: 1;
  }
  
  .grid33{
    grid-column-start: 3;
  }
  

  .grid34{
    grid-column-start: 2;
  }

  .grid35{
    grid-column-start: 3;
  }

  .grid36{
    grid-column-start: 2;
  }

  .grid37{
    grid-column-start: 3;
  }

  .grid138{
    grid-column-start: 1;
  }

  .grid39{
    grid-column-start: 3;
  }

  .grid40{
    grid-column-start: 1;
  }

  .grid42{
    grid-column-start: 2;
  }

  .grid43{
    grid-column-start: 2;
  }

  .grid44{
    grid-column-start: 3;
  }

  .grid45{
    grid-column-start: 1;
  }

  .grid46{
    grid-column-start: 2;
  }

  .grid47{
    grid-column-start: 1;
  }

  .grid48{
    grid-column-start: 2;
  }


}



@media screen and (max-width: 1200px) and (orientation: landscape) {
  .timeInstagram div {
    // max-width: 360px;
    // max-height: 360px;
    // overflow: hidden;
    display: grid;
    width: 237px;
    height: 237px;
    overflow: hidden;
    transition: 0.5s;
    justify-content: center;

  }
}

@media screen and (max-width: 917px) {
  .timeInstagram{
    div{
      width: 200px;
      height: 200px;
    }
  } 
}

@media screen and (max-width: 669px) {
  .sectionInstagram{

    .timeInstagram{
      grid-column-start: 2;
      grid-column-end: 3;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-columns: auto;

      justify-items: center;
      gap: 20px;
      margin-bottom: 100px;
      
        .instaImage{
          width: auto;
          height: 172px;
      
        } 
    }

  }
  .timeInstagram div {
    // max-width: 360px;
    // max-height: 360px;
    // overflow: hidden;
    display: grid;
    width: 150px;
    height: 150px;
    overflow: hidden;
    transition: 0.5s;
    justify-content: center;

  }
}


@media screen and (max-width: 569px) {
  .sectionInstagram{

    .timeInstagram{
      grid-column-start: 2;
      grid-column-end: 3;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-columns: auto;

      justify-items: center;
      gap: 20px;
      margin-bottom: 100px;
      
        .instaImage{
          width: auto;
          height: 172px;
      
        } 
    }

  }
  .timeInstagram div {
    // max-width: 360px;
    // max-height: 360px;
    // overflow: hidden;
    display: grid;
    width: 120px;
    height: 120px;
    overflow: hidden;
    transition: 0.5s;
    justify-content: center;

  }



  .grid1{
    grid-column-start: 1;
  }
  
  .grid2{
    grid-column-start: 1;
  }
  
  .grid3{
    grid-column-start: 2;
  }
  
  .grid4{
    grid-column-start: 1;
  }
  
  .grid5{
    grid-column-start: 1;
  }
  
  .grid6{
    grid-column-start: 2;
  }
  
  .grid7{
    grid-column-start: 2;
  }
  
  .grid8{
    grid-column-start: 2;
  }
  
  .grid9{
    grid-column-start: 1;
  }
  
  .grid0{
    grid-column-start: 1;
  }
  

  .grid10{
    grid-column-start: 2;
  }

  .grid11{
    grid-column-start: 1;
  }

  .grid12{
    grid-column-start: 2;
  }

  .grid13{
    grid-column-start: 2;
  }

  .grid14{
    grid-column-start: 1;
  }

  .grid15{
    grid-column-start: 2;
  }

  .grid16{
    grid-column-start: 1;
  }

  .grid17{
    grid-column-start: 2;
  }

  .grid18{
    grid-column-start: 2;
  }

  .grid19{
    grid-column-start: 1;
  }

  .grid20{
    grid-column-start: 1;
  }

  .grid21{
    grid-column-start: 2;
  }

  .grid22{
    grid-column-start: 1;
  }

  .grid23{
    grid-column-start: 2;
  }


  .grid24{
    grid-column-start: 1;
  }
  
  .grid25{
    grid-column-start: 1;
  }
  
  .grid26{
    grid-column-start: 2;
  }
  
  .grid27{
    grid-column-start: 1;
  }
  
  .grid28{
    grid-column-start: 1;
  }
  
  .grid29{
    grid-column-start: 2;
  }
  
  .grid30{
    grid-column-start: 2;
  }
  
  .grid31{
    grid-column-start: 2;
  }
  
  .grid32{
    grid-column-start: 1;
  }
  
  .grid33{
    grid-column-start: 1;
  }
  

  .grid34{
    grid-column-start: 2;
  }

  .grid35{
    grid-column-start: 1;
  }

  .grid36{
    grid-column-start: 2;
  }

  .grid37{
    grid-column-start: 2;
  }

  .grid38{
    grid-column-start: 1;
  }

  .grid39{
    grid-column-start: 2;
  }

  .grid40{
    grid-column-start: 1;
  }

  .grid41{
    grid-column-start: 2;
  }

  .grid42{
    grid-column-start: 2;
  }

  .grid43{
    grid-column-start: 1;
  }

  .grid44{
    grid-column-start: 1;
  }

  .grid45{
    grid-column-start: 2;
  }

  .grid46{
    grid-column-start: 1;
  }

  .grid47{
    grid-column-start: 2;
  }

}

